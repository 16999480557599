var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data6, _vm$selectedSchool, _vm$selectedSchool$sc, _vm$selectedRumpun, _vm$selectedRumpun$ru;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {}, [_c('h3', {
    staticClass: "text-white"
  }, [_c('strong', [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.jadwal_name))])]), _c('span', {
    staticClass: "d-block text-white"
  }, [_vm._v("Paket: " + _vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.name))]), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center text-body mr-5"
  }, [_c('feather-icon', {
    staticClass: "mr-50 text-white",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v("Waktu Mulai")])], 1), _c('div', {
    staticClass: "d-flex align-items-center text-body ml-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50 text-white",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v("Waktu Berakhir")])], 1)]), _c('div', {
    staticClass: "d-flex align-items-center mt-1 mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [(_vm$data3 = _vm.data) !== null && _vm$data3 !== void 0 && _vm$data3.info_start ? _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(_vm._s(_vm.getTanggal((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.info_start)) + " WIB")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center text-body ml-1"
  }, [(_vm$data5 = _vm.data) !== null && _vm$data5 !== void 0 && _vm$data5.info_end ? _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(_vm._s(_vm.getTanggal((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.info_end)) + " WIB")]) : _vm._e()])]), _vm.thereRumpunAndMajors ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "light"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.viewSelectedMajors($event);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "text-dark",
    attrs: {
      "icon": "FileIcon",
      "size": "12"
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("Jurusan Pilihan Anda (" + _vm._s(_vm.selectedMajors.length) + ")")])], 1)]) : _vm._e(), _vm.thereSelectedSchool ? _c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "size": "sm",
      "variant": "light"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.viewSelectedSchool($event);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "text-dark",
    attrs: {
      "icon": "InfoIcon",
      "size": "12"
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("Sekolah Pilihan Anda")])], 1)]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('b-row', {}, [_c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Peserta")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(_vm._s(_vm.data.sum_quota) + "/" + _vm._s(_vm.data.max_quota))])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Total Waktu")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(" " + _vm._s(_vm.totalTimer) + " menit")])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Total Soal")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(_vm._s(_vm.totalSoal) + " Soal")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "header": 'Subtest (' + _vm.data.sum_subtest + ')',
      "header-text-variant": "black"
    }
  }, [_c('b-card-body', [_vm.dataSubtes.length > 0 ? _c('div', {}, _vm._l(_vm.dataSubtes, function (data, i) {
    var _data$ujian_data, _data$ujian_data2, _data$ujian_data3, _data$ujian_data4;
    return _c('div', {
      key: i
    }, [data.name && data.items ? _c('app-collapse', [_c('app-collapse-item', {
      attrs: {
        "title": data.name
      }
    }, _vm._l(data.items, function (item, i) {
      var _item$ujian_data, _item$ujian_data2, _item$ujian_data3, _item$ujian_data4, _item$ujian_data5;
      return _c('div', {
        key: i,
        staticClass: "mt-2"
      }, [_c('b-card', {
        staticClass: "border py-2 px-0",
        attrs: {
          "no-body": ""
        }
      }, [_c('b-card-body', [_c('div', {
        staticClass: "d-flex flex-column flex-sm-row justify-content-between align-items-center"
      }, [_c('div', {
        staticClass: "mb-2"
      }, [_c('h4', [_c('b', [_vm._v(" " + _vm._s(item.nama_ujian))])]), item.jadwal_id != null ? _c('span', [item.hasil && item.status == 'complete' ? _c('span', [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "CalendarIcon"
        }
      }), item !== null && item !== void 0 && item.start_time && item !== null && item !== void 0 && item.end_time ? _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(_vm.getTanggal(item.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(item.end_time)) + " WIB ")]) : _vm._e(), _c('div', {
        staticClass: "mt-1"
      }, [_c('feather-icon', {
        staticClass: "mr-50 text-success",
        attrs: {
          "icon": "ClockIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(item.timer) + " Menit")]), _c('feather-icon', {
        staticClass: "mr-50 ml-2 text-success",
        attrs: {
          "icon": "FileTextIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(item !== null && item !== void 0 && (_item$ujian_data = item.ujian_data) !== null && _item$ujian_data !== void 0 && _item$ujian_data.sum_soal ? item === null || item === void 0 ? void 0 : (_item$ujian_data2 = item.ujian_data) === null || _item$ujian_data2 === void 0 ? void 0 : _item$ujian_data2.sum_soal : 0) + " Soal")])], 1)], 1) : _c('span', [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "CalendarIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(_vm.getTanggal(item.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(item.end_time)) + " WIB ")]), _c('div', {}, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ClockIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(item.timer) + " Menit")]), _c('feather-icon', {
        staticClass: "mr-50 ml-2",
        attrs: {
          "icon": "FileTextIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(item !== null && item !== void 0 && (_item$ujian_data3 = item.ujian_data) !== null && _item$ujian_data3 !== void 0 && _item$ujian_data3.sum_soal ? item === null || item === void 0 ? void 0 : (_item$ujian_data4 = item.ujian_data) === null || _item$ujian_data4 === void 0 ? void 0 : _item$ujian_data4.sum_soal : 0) + " Soal")])], 1)], 1)]) : _c('span', [_c('i', {
        staticClass: "text-danger"
      }, [_vm._v("Jadwal Belum ditentukan")])])]), _c('div', {
        staticClass: "d-flex align-items-center text-body"
      }, [item.jadwal_id ? _c('span', [item.hasil && item.status == 'complete' ? _c('b-button', {
        attrs: {
          "variant": "outline-success"
        }
      }, [_vm._v("Selesai")]) : !item.in_schedule ? _c('b-button', {
        attrs: {
          "variant": "outline-secondary",
          "disabled": ""
        }
      }, [_vm._v("Tidak Masuk Jadwal")]) : !(item !== null && item !== void 0 && (_item$ujian_data5 = item.ujian_data) !== null && _item$ujian_data5 !== void 0 && _item$ujian_data5.soal_ids) ? _c('b-button', {
        attrs: {
          "variant": "outline-secondary",
          "disabled": ""
        }
      }, [_vm._v("Soal Kosong")]) : _c('b-button', {
        attrs: {
          "variant": "outline-primary"
        },
        on: {
          "click": function click($event) {
            return _vm.mulaiUjian(item);
          }
        }
      }, [_vm._v(" Mulai ")])], 1) : _c('span', [_c('b-button', {
        attrs: {
          "variant": "primary disabled",
          "disabled": ""
        }
      }, [_vm._v(" Jadwal Belum Ditentukan ")])], 1)])])])], 1)], 1);
    }), 0)], 1) : _c('b-card', {
      staticClass: "border",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-body', [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "align-items-center text-body"
    }, [_c('h4', [_c('b', [_vm._v(" " + _vm._s(data.nama_ujian))])]), data.jadwal_id != null ? _c('span', [data.status == 'complete' ? _c('span', [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "CalendarIcon"
      }
    }), data !== null && data !== void 0 && data.start_time && data !== null && data !== void 0 && data.end_time ? _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(_vm.getTanggal(data.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(data.end_time)) + " WIB ")]) : _vm._e(), _c('div', {}, [_c('feather-icon', {
      staticClass: "mr-50 text-success",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(data.timer) + " Menit ")]), _c('feather-icon', {
      staticClass: "mr-50 ml-2 text-success",
      attrs: {
        "icon": "FileTextIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(data !== null && data !== void 0 && (_data$ujian_data = data.ujian_data) !== null && _data$ujian_data !== void 0 && _data$ujian_data.sum_soal ? data === null || data === void 0 ? void 0 : (_data$ujian_data2 = data.ujian_data) === null || _data$ujian_data2 === void 0 ? void 0 : _data$ujian_data2.sum_soal : 0) + " Soal ")])], 1)], 1) : _c('span', [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "CalendarIcon"
      }
    }), data !== null && data !== void 0 && data.start_time && data !== null && data !== void 0 && data.end_time ? _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(_vm.getTanggal(data.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(data.end_time)) + " WIB ")]) : _vm._e(), _c('div', {}, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data.timer) + " Menit")]), _c('feather-icon', {
      staticClass: "mr-50 ml-2",
      attrs: {
        "icon": "FileTextIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && (_data$ujian_data3 = data.ujian_data) !== null && _data$ujian_data3 !== void 0 && _data$ujian_data3.sum_soal ? data === null || data === void 0 ? void 0 : (_data$ujian_data4 = data.ujian_data) === null || _data$ujian_data4 === void 0 ? void 0 : _data$ujian_data4.sum_soal : 0) + " Soal")])], 1)], 1)]) : _c('span', [_c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Jadwal Belum ditentukan")])])]), _c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [data.jadwal_id != null ? _c('span', [data.status == 'complete' ? _c('b-button', {
      attrs: {
        "variant": "outline-success"
      }
    }, [_vm._v("Selesai")]) : _c('b-button', {
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.mulaiUjian(data);
        }
      }
    }, [_vm._v(" Mulai ")])], 1) : _c('span', [_c('b-button', {
      attrs: {
        "variant": "primary disabled",
        "disabled": ""
      }
    }, [_vm._v(" Jadwal Belum Ditentukan ")])], 1)])])])], 1)], 1);
  }), 0) : _c('div', [_c('h5', {
    staticClass: "text-danger text-center mt-2"
  }, [_vm._v("Tidak ada Subtest")])]), _c('center', [_c('span', [_vm.is_lihat_hasil == true ? _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.lihatHasil();
      }
    }
  }, [_vm._v(" Lihat Hasil ")]) : _vm._e()], 1)])], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "view-selected-major"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$bvModal.hide('view-selected-major');
            }
          }
        }, [_vm._v("OK")])];
      },
      proxy: true
    }])
  }, [_vm.selectedSchool ? _c('section', [_c('h5', [_c('strong', [_vm._v("Pilihan Sekolah Kedinasan:")])]), _c('p', [_vm._v(_vm._s((_vm$selectedSchool = _vm.selectedSchool) === null || _vm$selectedSchool === void 0 ? void 0 : (_vm$selectedSchool$sc = _vm$selectedSchool.school) === null || _vm$selectedSchool$sc === void 0 ? void 0 : _vm$selectedSchool$sc.name))])]) : _vm._e(), _vm.selectedRumpun ? _c('section', {
    staticClass: "mb-2"
  }, [_c('h5', [_c('strong', [_vm._v("Pilihan Rumpun:")])]), _c('p', [_vm._v(_vm._s((_vm$selectedRumpun = _vm.selectedRumpun) === null || _vm$selectedRumpun === void 0 ? void 0 : (_vm$selectedRumpun$ru = _vm$selectedRumpun.rumpun) === null || _vm$selectedRumpun$ru === void 0 ? void 0 : _vm$selectedRumpun$ru.name))])]) : _vm._e(), _vm.selectedMajors.length > 0 ? _c('section', {
    staticClass: "mb-2"
  }, [_c('h5', [_c('strong', [_vm._v("Pilihan Jurusan (" + _vm._s(_vm.selectedMajors.length) + ")")])]), _c('table', {
    staticClass: "table table-bordered table-responsive w-100"
  }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Universitas")]), _c('th', [_vm._v("Jurusan")])]), _vm._l(_vm.selectedMajors, function (item, i) {
    var _item$school, _item$major;
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_c('strong', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$school = item.school) === null || _item$school === void 0 ? void 0 : _item$school.name))])]), _c('td', [_c('strong', [_c('i', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$major = item.major) === null || _item$major === void 0 ? void 0 : _item$major.name))])])])]);
  })], 2)]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }